import type { IBreakdown, IRequestPayloadBreakdownsAssign, IResponseBreakdownsAssigned, IResponseBreakdownsBalance, IResponseStripeProducts } from "~/types";
import { formatBreakdownsData } from "~/helpers/format-product";

// in future could be extend for other products if needed
async function getProducts () {
  const location = await useLocation();

  return useAPI<IResponseStripeProducts>(`/api/v1/payment/products/profile/1?origin=${location.country}`);
}

export async function getBreakdowns (): Promise<IBreakdown[]> {
  const response = await getProducts();
  const productTypeSearch = "VidSwap";

  if (response.data?.length) {
    const breakdowns = response.data.filter(item => item.type === productTypeSearch);
    return formatBreakdownsData(breakdowns);
  }

  return [];
}

export function getUserBreakdownsBalance () {
  return useAPI<IResponseBreakdownsBalance>("/api/v1/users/me/breakdowns");
}

export function processBreakdownAssignment (action: "assign" | "unassign", body: IRequestPayloadBreakdownsAssign) {
  return useAPI<IResponseBreakdownsAssigned>(`/api/v1/breakdowns/transfer/${action}`, { body, method: "POST" });
}
