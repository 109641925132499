<template>
  <div class="flex relative max-w-[273px] h-[290px] flex-col border border-neutral-light-200 rounded">
    <div class="flex flex-col items-center px-6 py-8">
      <div class="w-[82px] h-[28px] skeletonLoading dark:skeletonLoadingDark" />
      <div class="w-[156px] h-[48px] my-4 skeletonLoading dark:skeletonLoadingDark" />
      <div class="w-[225px] h-[72px] py-4 skeletonLoading dark:skeletonLoadingDark" />
      <div class="w-[225px] h-[44px] skeletonLoading dark:skeletonLoadingDark" />
    </div>
  </div>
</template>
