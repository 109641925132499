import { formatCurrency } from "./format-currency";
import type {
  IBreakdown,
  IResponseProduct,
  IResponseStripePrice
} from "~/types";
import { BREAKDOWN_PACKAGE_TYPE, BREAKDOWN_TYPE } from "~/constants";

export function formatBreakdownsData (
  breakdowns: Array<IResponseProduct>
): IBreakdown[] {
  const singleItems = breakdowns.filter(
    (item) =>
      item.metadata.breakdownPackageType ===
      BREAKDOWN_PACKAGE_TYPE.BREAKDOWN_SINGLE_GAME
  );

  return breakdowns
    .reduce((acc: IBreakdown[], { prices, productId, metadata }) => {
      prices.forEach((item) => {
        const saveValue = calculateBreakdownSaveValue(
          item,
          metadata,
          singleItems
        );

        acc.push(formatBreakdown(item, productId, metadata, saveValue));
      });

      return acc;
    }, [])
    .sort((a, b) => a.price - b.price);
}

function calculateBreakdownSaveValue (
  item: IResponseStripePrice,
  metadata: IResponseProduct["metadata"],
  singleItems: IResponseProduct[]
): number {
  const gamesQuantity = Number(metadata.breakdownPackageType?.split("-")[0]);

  if (gamesQuantity === 1) return 0;

  const singleItem = singleItems.find(
    (singleItem) => singleItem.metadata.breakdownType === metadata.breakdownType
  );
  const singleItemPrice = singleItem?.prices[0].price || 0;
  const savePrice = singleItemPrice * gamesQuantity - item.price;

  return savePrice > 0 ? savePrice : 0;
}

function formatBreakdown (
  item: IResponseStripePrice,
  productId: string,
  metadata: IResponseProduct["metadata"],
  saveValue: number
): IBreakdown {
  // gamesQuantity - gets from breakdown type which defined on BE. Example: "1-games", "10-games"
  const gamesQuantity = Number(metadata.breakdownPackageType?.split("-")[0]);
  const pricePerGame = item.price / gamesQuantity;

  return {
    id: item.id,
    currency: item.currency,
    price: item.price,
    productId,
    type: metadata.breakdownType || BREAKDOWN_TYPE.BASIC,
    packageType:
      metadata.breakdownPackageType ||
      BREAKDOWN_PACKAGE_TYPE.BREAKDOWN_SINGLE_GAME,
    gamesQuantity,
    priceFormatted: formatCurrency(item.price, item.currency),
    pricePerGameFormatted: formatCurrency(pricePerGame, item.currency),
    priceSaveFormatted: formatCurrency(saveValue, item.currency)
  };
}
