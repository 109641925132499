<script setup lang="ts">
import { BREAKDOWN_PACKAGE_TYPE } from "~/constants";
import type { IBreakdown } from "~/types";

const { t } = useI18n();
const props = defineProps<{
  breakdown: IBreakdown;
  disabled: boolean;
}>();
const emit = defineEmits(["buyClick"]);

const isHighlighted = computed(() => props.breakdown.packageType === BREAKDOWN_PACKAGE_TYPE.BREAKDOWN_TEN_GAMES);
const breakdownTitle = computed(() => {
  return props.breakdown.packageType === BREAKDOWN_PACKAGE_TYPE.BREAKDOWN_SINGLE_GAME
    ? t("pages.breakdowns.buy_card.1_games")
    : t("pages.breakdowns.buy_card.n_games", { n: props.breakdown.gamesQuantity });
});
const breakdownDescription = computed(() => {
  return props.breakdown.packageType === BREAKDOWN_PACKAGE_TYPE.BREAKDOWN_SINGLE_GAME
    ? t("pages.breakdowns.buy_card.1_games_description")
    : t("pages.breakdowns.buy_card.n_games_description", {
        price: props.breakdown.priceFormatted,
        save: props.breakdown.priceSaveFormatted,
      });
});

function onBuyButtonClick() {
  emit("buyClick", props.breakdown);
}
</script>

<template>
  <div
    class="flex relative max-w-[273px] flex-col dark:text-[#FFFFFF] border border-neutral-light-200 rounded"
    :class="[isHighlighted && ['highlighted', '!border-[#61BE77]', '!border-[2px]', 'mt-[calc(2rem-3px)]']]"
  >
    <div
      v-if="isHighlighted"
      class="absolute"
      :class="[isHighlighted && ['-top-[25px]', 'left-1/2', '-translate-x-1/2']]"
    >
      <div class="flex items-center font-bold text-white">
        <UIcon
          name="popular"
          class="pr-2"
        />
        <span
          class="text-xs text"
          :class="[isHighlighted && ['relative', 'top-[2px]']]"
        >
          {{ t("pages.breakdowns.buy_card.most_popular_label") }}
        </span>
      </div>
    </div>

    <div class="flex flex-col items-center px-6 py-8">
      <h3 class="text-lg font-bold">
        {{ breakdownTitle }}
      </h3>
      <div class="my-4">
        <span class="text-5xl font-bold">{{ props.breakdown.pricePerGameFormatted }}</span>
        <span class="text-s text-neutral-light-800">/ {{ t("pages.breakdowns.buy_card.game_text") }}</span>
      </div>
      <div class="font-medium text-sm text-neutral-light-800 text-center max-w-[225px] py-4 px-1">
        {{ breakdownDescription }}
      </div>

      <UButton
        block
        size="lg"
        :variant="isHighlighted ? 'primary' : 'primary-outline'"
        :disabled="props.disabled"
        :label="t('pages.breakdowns.buy_card.buy_button_text')"
        @click="onBuyButtonClick"
      />
    </div>
  </div>
</template>

<style>
.highlighted:before {
  content: "";
  @apply w-[273px] h-[32px] -left-[2px] -top-[31px] bg-[#61BE77] absolute rounded-t;
}
</style>
