import { loadStripe } from "@stripe/stripe-js";
import type { IRequestPayloadBreakdownsPurchase } from "~/types";

export async function createCheckoutSession (data: { priceId: string; quantity: number }[]): Promise<object> {
  const runtimeConfig = useRuntimeConfig();
  const stripe: any = await loadStripe(`${runtimeConfig.public.stripeApiKey}`);
  const location = await useLocation();
  const newPaymentSession = (payload: { priceId: string; quantity: number }[]) => {
    const body: IRequestPayloadBreakdownsPurchase = { prices: payload, origin: location.country };

    // Share A Sale integration with Payment BE
    // Check comments: https://pixellot.atlassian.net/browse/NPS-14911
    const sscid = useCookie("sas_sscid");

    if (sscid.value)
      body.affiliate = { sscid: sscid.value };

    return useAPI<{ sessionId: string }>("/api/v1/payment/payments/create", { body, method: "POST" });
  };
  const { sessionId } = await newPaymentSession(data);

  return stripe.redirectToCheckout({ sessionId });
}
